import { getHeadersForTable } from "@/utils/table"
import { TABLE_NAMES,
  ISSUE_PROPERTIES,
  FIELD_TYPES,
  MAX_CHARACTER_LIMIT,
  STRING,
  SPEAK_UP_FIELDS_ITEMS
} from "@/constants"
import moment from "moment"
import ThemisInput from "@/components/shared/input"
import ThemisValidationStatusIcons from "@/components/shared/validation-status-icons"
import { sortIndices } from "@/utils"

export default {
  name      : "SpeakupFieldsValidationTable",
  components: {
    ThemisInput,
    ThemisValidationStatusIcons
  },
  props: {
    pIsIssueFormsEnabled       : Boolean,
    pIsIssueFieldsEnabled      : Boolean,
    pIssueFields               : Array,
    pFormTemplateConfigurations: Array,
    pIssueFormTemplates        : Array,
    pItemsForTable             : Array,
    pOptionListItems           : Array,
    pIssueResolutionsMap       : Object,
    pCaseStatusesMap           : Object,
    pFieldsMap                 : Object
  },
  emits: ["speakupFieldMappingStatus"],
  data : () => ({
    selectedDropDownItems             : new Map(),
    headerAndChildrenDropDownListItems: new Map(),
    selectedSpeakUpField              : [],
    itemsForValidationTable           : [],
    errorToBeDisplayed                : {},
    defaultStateOfOuterIcon           : true,
    validationSuccess                 : false,
    validationFailure                 : false,
    validationInProgress              : false
  }),
  computed: {
    calculateHeightForTable() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight

      if (this.itemsForValidationTable.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      }  else if (!this.itemsForValidationTable.length) {
        heightOfTable += dataRowHeight
      } else {
        heightOfTable += this.itemsForValidationTable.length * dataRowHeight
      }
      return heightOfTable
    },
    filterSpeakUpFieldsDropDownItems() {
      const arrayOfSelectedDropDownItems = Array.from(this.selectedDropDownItems.values())
      const itemsForSpeakUpFields        = [...this.computeItemsForSpeakFields()]

      this.itemsForValidationTable.forEach(item => {
        let modifiedArrayOfSelectedDropDownItems
        if (arrayOfSelectedDropDownItems.includes(item.speakupFields)) {
          modifiedArrayOfSelectedDropDownItems = arrayOfSelectedDropDownItems.filter(
            selectedItem => selectedItem !== item.speakupFields
          )
          item.speakUpFieldsItems              = itemsForSpeakUpFields.filter(subItem => {
            return !modifiedArrayOfSelectedDropDownItems.includes(subItem.value)
          })
          item.speakUpFieldsItems              = this.removeHeadersFromDropDown(item)
        }
      })

      const filteredCsvDataValidationItems = this.itemsForValidationTable.filter(item => {
        return !arrayOfSelectedDropDownItems.includes(item.speakupFields)
      })

      filteredCsvDataValidationItems.forEach(item => {
        item.speakUpFieldsItems = itemsForSpeakUpFields.filter(subItem => {
          return !arrayOfSelectedDropDownItems.includes(subItem.value)
        })
        item.speakUpFieldsItems = this.removeHeadersFromDropDown(item)
      })
    },
    headersForCsvDataValidationTable() {
      return getHeadersForTable(TABLE_NAMES.CSV_DATA_VALIDATION, this.$t.bind(this))
    },
    issueFormTemplateFieldsMap() {
      const issueFormTemplateFieldsMap = new Map()
      for (const configuration of this.pFormTemplateConfigurations) {
        const field = this.pFieldsMap[configuration.fieldId]
        if (field.type !== FIELD_TYPES.MULTIPLE_OPTION_LIST.value) {
          let fields = [field]
          if (issueFormTemplateFieldsMap.has(configuration.formTemplateId)) {
            fields = [...fields, ...issueFormTemplateFieldsMap.get(configuration.formTemplateId)]
          }
          issueFormTemplateFieldsMap.set(configuration.formTemplateId, fields)
        }
      }
      return issueFormTemplateFieldsMap
    },
    itemsForSpeakFields() {
      this.headerAndChildrenDropDownListItems.set(this.$t("1916"), [this.$t("1917"), this.$t("1918"), this.$t("1919"), this.$t("1920"), this.$t("1921")])

      const itemsToDisplay = [
        {
          header: this.$t("1916")
        },
        {
          name : this.$t("1917"),
          value: ISSUE_PROPERTIES.CREATED_AT
        },
        {
          name : this.$t("1918"),
          value: ISSUE_PROPERTIES.STATUS_ID
        },
        {
          name : this.$t("1919"),
          value: ISSUE_PROPERTIES.RESOLUTION_ID
        },
        {
          name : this.$t("1920"),
          value: ISSUE_PROPERTIES.SUMMARY
        },
        {
          name : this.$t("1921"),
          value: ISSUE_PROPERTIES.DESCRIPTION
        }
      ]

      if (this.pIsIssueFieldsEnabled && this.pIssueFields.length) {
        itemsToDisplay.push({
          header: this.$t("1922")
        })
        const filteredIssueFields       = this.pIssueFields
          .filter(issueField => this.pFieldsMap[issueField.fieldId]?.type !== FIELD_TYPES.MULTIPLE_OPTION_LIST.value)
          .map(issueField => ({
            issueFieldId: issueField.id,
            ...this.pFieldsMap[issueField.fieldId]
          }))
        const uniqueFilteredIssueFields = [...new Set(filteredIssueFields)]
        uniqueFilteredIssueFields.forEach(field => {
          itemsToDisplay.push({
            name : field.label,
            value: `issue-field-id-${field.issueFieldId}`
          })
        })
        const issueFieldIds = uniqueFilteredIssueFields.map(field =>`issue-field-id-${field.issueFieldId}`)
        this.headerAndChildrenDropDownListItems.set(this.$t("1922"), issueFieldIds)
      }

      if (this.pIsIssueFormsEnabled && this.pIssueFormTemplates.length) {
        const filteredIssueFormTemplates = this.pIssueFormTemplates.filter(issueFormTemplate =>{
          const formTemplateConfigurations = this.pFormTemplateConfigurations.filter(
            configuration => configuration.formTemplateId === issueFormTemplate.id
          )

          const formTemplateConfigurationsWithoutMultipleOptionList = formTemplateConfigurations.filter(
            configuration => this.pFieldsMap[configuration.fieldId]?.type !== FIELD_TYPES.MULTIPLE_OPTION_LIST.value
          )

          if(formTemplateConfigurationsWithoutMultipleOptionList.length > 0) {
            return true
          }
        })

        filteredIssueFormTemplates.forEach(issueFormTemplate => {
          itemsToDisplay.push({
            header: issueFormTemplate.name
          })

          const fieldNames                 = this.getIssueFormTemplateFieldNames(issueFormTemplate.id)
          const formTemplateConfigurations = this.pFormTemplateConfigurations.filter(
            configuration => configuration.formTemplateId === issueFormTemplate.id
            && this.pFieldsMap[configuration.fieldId]?.type !== FIELD_TYPES.MULTIPLE_OPTION_LIST.value
          )

          fieldNames.forEach(field => {
            const formTemplateConfiguration = formTemplateConfigurations.find(
              formTemplateConfiguration => formTemplateConfiguration.fieldId === field.fieldId &&
              formTemplateConfiguration.formTemplateId === issueFormTemplate.id
            )
            itemsToDisplay.push({
              name : field.label,
              value: `form-template-configuration-id-${formTemplateConfiguration.id}`
            })
            const formTemplateConfigurationIds = formTemplateConfigurations.map(config => `form-template-configuration-id-${config.id}`)
            this.headerAndChildrenDropDownListItems.set(issueFormTemplate.name, formTemplateConfigurationIds)
          })
        })
      }
      return [{
        value: SPEAK_UP_FIELDS_ITEMS.SKIP_IN_IMPORT,
        name : this.$t("1915")
      }, ...itemsToDisplay]
    }
  },
  methods: {
    computeItemsForSpeakFields() {
      return this.itemsForSpeakFields
    },
    getValidationRules(item) {
      return item.validationFailure ?
      `speakup_fields_error:true,${item.errorToBeDisplayed.message},${item.errorToBeDisplayed.sortedIndices}` : "speakup_fields_error:false"
    },
    handleSelectionChange(item) {
      this.setInProgressValidationState(item)

      if (item.speakupFields !== SPEAK_UP_FIELDS_ITEMS.SKIP_IN_IMPORT) {
        this.selectedDropDownItems.set(item.header, item.speakupFields)
      } else {
        this.selectedDropDownItems.delete(item.header)
      }

      this.filterSpeakUpFieldsDropDownItems

      const allRowsOfCsvHeaderArray = item.headerColumnRows

      if (item.speakupFields === SPEAK_UP_FIELDS_ITEMS.SKIP_IN_IMPORT) {
        this.setDefaultValidationState(item)
        return
      } else if (item.speakupFields === null ) {
        this.setDefaultValidationState(item)
        return
      } else if (item.speakupFields === SPEAK_UP_FIELDS_ITEMS.RESOLUTION_ID) {
        const invalidResolutionIndices = []

        allRowsOfCsvHeaderArray.forEach((resolution, index) => {
          if (this.isValueNonEmpty(resolution)) {
            const isResolutionValid = Object.values(this.pIssueResolutionsMap).some(
              resolutionItem => resolutionItem.name === resolution
            )
            if (!isResolutionValid) {
              invalidResolutionIndices.push(index + 1)
            }
          }
        })

        if (invalidResolutionIndices.length) {
          item.validationFailure = true
          const message          = "1927"
          this.setValidationFailure(item, message, invalidResolutionIndices)
          return
        }
      } else if (item.speakupFields === SPEAK_UP_FIELDS_ITEMS.STATUS_ID) {

        const isStatusMissing = this.checkMissingValuesForRequiredFields(item, allRowsOfCsvHeaderArray)
        if (isStatusMissing) {
          return
        }

        const invalidStatusIndices = []
        allRowsOfCsvHeaderArray.forEach((status, index) => {
          const isStatusPresent = Object.values(this.pCaseStatusesMap).some(
            statusItem => statusItem.name === status
          )

          if (!isStatusPresent) {
            invalidStatusIndices.push(index + 1)
          }
        })

        if (invalidStatusIndices.length) {
          this.setValidationFailure(item, "1925", invalidStatusIndices)
          return
        }
      } else if (item.speakupFields === SPEAK_UP_FIELDS_ITEMS.CREATED_AT) {

        const isCreatedAtMissing = this.checkMissingValuesForRequiredFields(item, allRowsOfCsvHeaderArray)
        if(isCreatedAtMissing) {
          return
        }

        const invalidDateTimeIndices = this.validateDateTimeFields(allRowsOfCsvHeaderArray)

        if(invalidDateTimeIndices.length) {
          this.setValidationFailure(item, "1968", invalidDateTimeIndices)
          return
        }
      } else if (item.speakupFields === SPEAK_UP_FIELDS_ITEMS.DESCRIPTION) {
        const invalidDescriptionIndices = this.validateFieldWithFiftyThousandLimit(allRowsOfCsvHeaderArray)

        if(invalidDescriptionIndices.length) {
          this.setValidationFailure(item, "1929", invalidDescriptionIndices)
          return
        }
      } else if (item.speakupFields === SPEAK_UP_FIELDS_ITEMS.SUMMARY) {
        const invalidFieldLengthIndices = this.validateFieldWithHundredAndTwentyEightLimit(allRowsOfCsvHeaderArray)
        if(invalidFieldLengthIndices.length) {
          this.setValidationFailure(item, "1928", invalidFieldLengthIndices)
          return
        }
      } else if (item.speakupFields.startsWith("issue-field-id")) {
        const issueFieldId     = item.speakupFields.slice("issue-field-id-".length)
        const issueFieldObject = this.pIssueFields.find(issueField => issueField.id === +issueFieldId)

        const field = Object.values(this.pFieldsMap).find(
          field => field.id === issueFieldObject.fieldId
        )

        const invalidFieldIndices = this.validateField(field, allRowsOfCsvHeaderArray)
        if (invalidFieldIndices.length) {
          const message = this.errorMessageMap(field)
          this.setValidationFailure(item, message, invalidFieldIndices)
          return
        }

      } else  if (item.speakupFields.startsWith("form-template-configuration-id-")) {
        const formTemplateConfigurationId = item.speakupFields.slice("form-template-configuration-id-".length)
        const formTemplateConfiguration   = this.pFormTemplateConfigurations.find(
          formTemplateConfiguration => formTemplateConfiguration.id === +formTemplateConfigurationId
        )

        const fieldId = formTemplateConfiguration.fieldId
        const field   = Object.values(this.pFieldsMap).find(field => field.id === fieldId)

        const invalidFieldIndices = this.validateField(field, allRowsOfCsvHeaderArray)
        if (invalidFieldIndices.length) {
          const message = this.errorMessageMap(field)
          this.setValidationFailure(item, message, invalidFieldIndices)
          return
        }
      }
      this.setValidationSuccessState(item)
    },
    setDefaultValidationState(item) {
      item.validationInProgress    = false
      item.validationSuccess       = false
      item.validationFailure       = false
      item.defaultStateOfOuterIcon = true
      item.errorToBeDisplayed      = {}
      const objectHasKeys          = !!Object.keys(item.errorToBeDisplayed).length
      this.$emit("speakupFieldMappingStatus", {
        mappedSpeakupField: item.speakupFields,
        header            : item.header,
        error             : objectHasKeys
      })
    },
    setInProgressValidationState(item) {
      item.validationInProgress    = true
      item.validationSuccess       = false
      item.validationFailure       = false
      item.defaultStateOfOuterIcon = false
    },
    setValidationFailure(item, message, indicesWithoutValues) {
      item.validationFailure    = true
      item.validationInProgress = false
      item.validationSuccess    = false
      item.errorToBeDisplayed   = {
        message      : message,
        indicesLength: indicesWithoutValues.length,
        sortedIndices: sortIndices(indicesWithoutValues)
      }
      const objectHasKeys       = !!Object.keys(item.errorToBeDisplayed).length
      this.$emit("speakupFieldMappingStatus", {
        mappedSpeakupField: item.speakupFields,
        header            : item.header,
        error             : objectHasKeys
      })
    },
    setValidationSuccessState(item) {
      item.validationSuccess       = true
      item.validationInProgress    = false
      item.validationFailure       = false
      item.defaultStateOfOuterIcon = false
      item.errorToBeDisplayed      = {}
      const objectHasKeys          = !!Object.keys(item.errorToBeDisplayed).length
      this.$emit("speakupFieldMappingStatus", {
        mappedSpeakupField: item.speakupFields,
        header            : item.header,
        error             : objectHasKeys
      })
    },
    getIssueFormTemplateFieldNames(formTemplateId) {
      return this.issueFormTemplateFieldsMap
        ?.get(formTemplateId)
        ?.map(field => ({
          label  : field?.label,
          fieldId: field.id
        }))
    },
    isValueNonEmpty(value) {
      return value !== ""
    },
    validateBooleanField(allRowsArray) {
      const invalidBooleanIndices = []

      allRowsArray.forEach((booleanValue, index) => {
        if (this.isValueNonEmpty(booleanValue) && booleanValue !== STRING.TRUE && booleanValue !== STRING.FALSE) {
          invalidBooleanIndices.push(index + 1)
        }
      })
      return invalidBooleanIndices
    },
    validateNumberField(allRowsArray) {
      const invalidNumberIndices = []

      allRowsArray.forEach((numberValue, index) => {
        if (this.isValueNonEmpty(numberValue) && isNaN(Number(numberValue))) {
          invalidNumberIndices.push(index + 1)
        }
      })
      return invalidNumberIndices
    },
    validateFieldWithHundredAndTwentyEightLimit(allRowsArray) {
      const invalidFieldLengthIndices = []

      allRowsArray.forEach((summary, index) => {
        if (summary.length > MAX_CHARACTER_LIMIT.ISSUE_SUMMARY) {
          invalidFieldLengthIndices.push(index + 1)
        }
      })
      return invalidFieldLengthIndices
    },
    validateFieldWithFiftyThousandLimit(allRowsArray) {
      const invalidFieldLengthIndices = []

      allRowsArray.forEach((description, index) => {
        if (description.length > MAX_CHARACTER_LIMIT.ISSUE_DESCRIPTION_MAX_VALUE) {
          invalidFieldLengthIndices.push(index + 1)
        }
      })
      return invalidFieldLengthIndices
    },
    validateDateTimeFields(allRowsArray) {
      const invalidDateTimeIndices = []

      allRowsArray.forEach((dateString, index) => {
        if(this.isValueNonEmpty(dateString)) {
          const parsedDate = moment(dateString, moment.ISO_8601, true)

          if (!parsedDate.isValid()) {
            invalidDateTimeIndices.push(index + 1)
            return
          }

          const isoString       = parsedDate.toISOString()
          const isDateTimeValid = dateString === isoString || dateString === isoString.replace("Z", "+00:00")

          if (!isDateTimeValid) {
            invalidDateTimeIndices.push(index + 1)
          }
        }
      })
      return invalidDateTimeIndices
    },
    validateDateField(allRowsArray) {
      const invalidDateIndices = []

      allRowsArray.forEach((dateToValidate, index) => {
        if(this.isValueNonEmpty(dateToValidate)) {
          const isDateValid = moment(dateToValidate, "YYYY-MM-DD", true).isValid()
          if (!isDateValid) {
            invalidDateIndices.push(index + 1)
          }
        }
      })
      return invalidDateIndices
    },
    validateOptionListItems(optionListItems, optionListId) {
      const invalidOptionListItemIndices = []
      const currentOptionListItems       = this.pOptionListItems
        .filter(optionListItem => optionListItem.optionListId === optionListId)

      const filteredOptionListItems = this.getFilteredOptionListItems(currentOptionListItems)
      optionListItems.forEach((optionListItem, index) => {
        if (this.isValueNonEmpty(optionListItem)) {
          const isOptionListItemPresent = filteredOptionListItems.some(eachOptionListItem =>{
            return eachOptionListItem.name === optionListItem
          })

          if (!isOptionListItemPresent) {
            invalidOptionListItemIndices.push(index + 1)
          }
        }
      })
      return invalidOptionListItemIndices
    },
    checkMissingValuesForRequiredFields(item, allRowsOfCsvHeaderArray) {
      const indicesWithoutValues = []
      if (item.speakupFields === SPEAK_UP_FIELDS_ITEMS.CREATED_AT
        || item.speakupFields === SPEAK_UP_FIELDS_ITEMS.STATUS_ID
      )  {
        item.headerColumnRows.forEach((row, index) => {
          if (allRowsOfCsvHeaderArray[index] === "") {
            indicesWithoutValues.push(index + 1)
          }
        })

        if(indicesWithoutValues.length) {
          const message = item.speakupFields === SPEAK_UP_FIELDS_ITEMS.CREATED_AT ? "1924" : "1926"
          this.setValidationFailure(item, message, indicesWithoutValues)
          return true
        }
      }
      return false
    },
    validateField(field, allRowsOfCsvHeaderArray ) {
      switch (field.type) {
        case FIELD_TYPES.BOOLEAN.value:
          return this.validateBooleanField(allRowsOfCsvHeaderArray)
        case FIELD_TYPES.NUMBER.value:
          return this.validateNumberField(allRowsOfCsvHeaderArray)
        case FIELD_TYPES.OPTION_LIST.value:
          return this.validateOptionListItems(allRowsOfCsvHeaderArray, field.optionListId)
        case FIELD_TYPES.SHORT_TEXT.value:
          return this.validateFieldWithHundredAndTwentyEightLimit(allRowsOfCsvHeaderArray)
        case FIELD_TYPES.DATE.value:
          return this.validateDateField(allRowsOfCsvHeaderArray)
        case FIELD_TYPES.DATE_TIME.value:
          return this.validateDateTimeFields(allRowsOfCsvHeaderArray)
        case FIELD_TYPES.LONG_TEXT.value:
          return this.validateFieldWithFiftyThousandLimit(allRowsOfCsvHeaderArray)
      }
    },
    errorMessageMap(field) {
      switch(field.type) {
        case FIELD_TYPES.BOOLEAN.value:
          return "1934"
        case FIELD_TYPES.NUMBER.value:
          return "1930"
        case FIELD_TYPES.OPTION_LIST.value:
          return "1933"
        case FIELD_TYPES.SHORT_TEXT.value:
          return "1931"
        case FIELD_TYPES.DATE.value:
          return "1923"
        case FIELD_TYPES.DATE_TIME.value:
          return "1968"
        case FIELD_TYPES.LONG_TEXT.value:
          return "1932"
      }
    },
    removeHeadersFromDropDown(item) {
      const headersToRemove    = []
      let currentHeader        = null
      let hasValuesUnderHeader = false

      item.speakUpFieldsItems.forEach(subItem => {
        if (subItem.header) {
          if (currentHeader && !hasValuesUnderHeader) {
            headersToRemove.push(currentHeader)
          }
          currentHeader        = subItem.header
          hasValuesUnderHeader = false
        } else {
          hasValuesUnderHeader = true
        }
      })

      if (currentHeader && !hasValuesUnderHeader) {
        headersToRemove.push(currentHeader)
      }

      item.speakUpFieldsItems = item.speakUpFieldsItems.filter(subItem => {
        return !headersToRemove.includes(subItem.header)
      })
      return item.speakUpFieldsItems
    },
    getFilteredOptionListItems(optionListItems) {
      const uniqueParentIds = new Set(optionListItems.map(item => item.parentId).filter(id => id !== null))

      const leafNodes = optionListItems.filter(optionListItem =>
        uniqueParentIds.has(optionListItem.parentId) && !uniqueParentIds.has(optionListItem.id)
      )

      return leafNodes.length > 0
        ? leafNodes
        : optionListItems
    }
  },
  watch: {
    pItemsForTable: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.length) {
          this.selectedDropDownItems = new Map()
          this.headerAndChildrenDropDownListItems = new Map(),
          this.itemsForValidationTable = newValue.map(item => {
            return {
              ...item,
              speakupFields          : SPEAK_UP_FIELDS_ITEMS.SKIP_IN_IMPORT,
              speakUpFieldsItems     : this.computeItemsForSpeakFields(),
              defaultStateOfOuterIcon: true,
              validationInProgress   : false,
              validationSuccess      : false,
              validationFailure      : false,
              errorToBeDisplayed     : {}
            }
          })
        }
      }
    }
  }
}